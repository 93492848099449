body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.iframe-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.iframe-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}
